import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl, ListGroup, ListGroupItem, Button, Modal } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./JoinAMeeting.css";

export default function JoinAMeeting(props) {
  const [name, setName] = useState("");
  const [mid, setMid] = useState("");
  const [pw, setPw] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return name.length > 0 && mid.length > 0 && pw.length > 0;
  }

  function geturl(id, pw, name) {
    return API.get("meetings", `/meetings/join/${mid}/${pw}/${name}/attendee`);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
        const meetingurl = await geturl(mid, pw, name);

        if (meetingurl.url.match(/ERROR/)) {
          let msg = meetingurl.url.replace(/.*ERROR:/g, "");
          const confirmed = window.confirm(
             "Can not join the meeting now- " + msg + ". Please check and try again"
          );
          setIsLoading(false);
        } else {
          const confirmed = window.confirm(
             "Are you sure you want to goto this meeting?" + meetingurl.url
          );

          if (!confirmed) {
            return;
          }
          window.open(meetingurl.url);
//        window.location.assign(meetingurl.url);
          setIsLoading(false);
        }
      } catch (e) {
        alert(e);
      }
 }

 return (
    <div className="JoinAMeeting">
       
       <hr /><p><b>Enter the following info to join a meeting:</b></p> <hr />
       <form onSubmit={handleSubmit}>
          <FormGroup controlId="mid">
            Meeting ID:
            <FormControl
              type="input"
              value={mid}
              onChange={e => setMid(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="pw">
            Meeting Password:
            <FormControl
              type="input"
              value={pw}
              onChange={e => setPw(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="name">
            Your Full Name:
            <FormControl
              type="input"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </FormGroup>

          <LoaderButton
            block
            type="submit"
            text="Join"
            bsSize="large"
            bsStyle="primary"
            isLoading={isLoading}
            disabled={!validateForm()}
          />
       </form>
    </div>
  );
}
