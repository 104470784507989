import React from "react";
import "./About.css";

export default function About(props) {
    return (
      <div className="about">
        <h3>Affordable Online Meetings for Everyone</h3>
        <p>Are you wondering how to get a free online meeting for a quick meetup, team discussion/collabration, product demo, online teaching or any other interaction with people online? This application is for you. Signup is very quick and simple. After signup/login, you can create a new meeting from the home page and start it rightaway. You can invite others to join the meeting. Give it a try now!</p>
      </div>
    );
}
