import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup, ListGroupItem, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./Home.css";
import { API } from "aws-amplify";

export default function Home(props) {
  const [meetings, setMeetings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [freeMeeting, setFreeMeeting] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!props.isAuthenticated) {
        return;
      }

      try {
        const ms = await loadMeetings();
        setMeetings(ms);
        ms.forEach(function(value, index, arr) {
          if (value.meetingType.match(/^free/) ) {
            setFreeMeeting(false);
          }
        });
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated]);

  function loadMeetings() {
    return API.get("meetings", "/meetings");
  }

  function renderMeetingsList(meetings) {
    return [{}].concat(meetings).map((meeting, i) =>
      i !== 0 ? (
        <LinkContainer key={meeting.meetingID} to={`/meetings/${meeting.meetingID}`} className="meetingCard">
          <div>
            <b>{meeting.meetingName}</b> <br />
            {"room size: " + meeting.meetingSize} <br />
            {"hosted by " + meeting.meetingHost} <br/><br/>
            <p>{meeting.meetingDescription}</p> <br /> 
            {"created: " + new Date(meeting.createdAt).toLocaleString().replace(/,.*/, "")}
          </div>
        </LinkContainer>
      ) : (
        <LinkContainer key="new" to="/meetings/new" className="meetingCard">
          <div>
            <b>{"\uFF0B"}</b><b>Create a new meeting</b> <br />
            <p> Click here to create a new meeting</p><br/> <br/>
            {freeMeeting && renderFreeMeetingCreate()}
          </div>
        </LinkContainer>
      )
    );
  }

  function renderFreeMeetingCreate() {
    return (
      <LinkContainer key="new" to="/meetings/newfree" className="createFreeMeeting">
        <div>
          <b>{"\uFF0B"}</b><b>Create a free meeting</b>
          <p> Click here to create a new free meeting</p><br/>
        </div>
      </LinkContainer>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h3>Instant Meeting Done the Righway</h3>
        <p>Please signup/login to start meetings anytime and anywhere.</p>
      </div>
    );
  }

  function renderMeetings() {
    return (
      <div className="meetings">
        <h4>My Meetings:</h4>
        <div className="meetingList">
          {!isLoading && renderMeetingsList(meetings)}
        </div>
      </div>
    );
  }

  return (
    <div className="Home">
      {props.isAuthenticated ? renderMeetings() : renderLander()}
    </div>
  );
}
