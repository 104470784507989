export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  STRIPE_KEY: "pk_test_VaL9WQ6IbcZecbkcjzT9IFLK00AUgPzwRT",
  s3: {
    REGION: "us-west-2",
    BUCKET: "meeting-records"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://1a6jlcdgd9.execute-api.us-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_tsTVfbSvM",
    APP_CLIENT_ID: "1d554hjtpmekqqgc2bct63fvar",
    IDENTITY_POOL_ID: "us-west-2:9434ec64-fbce-4614-a050-db86ed24aaf0"
  }
};
