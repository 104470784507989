import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl, ListGroup, ListGroupItem, Button, Modal } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./JoinMeeting.css";

export default function JoinMeeting(props) {
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return name.length > 0;
  }

  function geturl(name) {
    return API.get("meetings", `/meetings/join/${props.match.params.id}/${props.match.params.pw}/${name}/${props.match.params.role}`);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
        const meetingurl = await geturl(name);

        if (meetingurl.url.match(/^ERROR/)) {
          let msg = meetingurl.url.replace(/ERROR:/,"");
          const confirmed = window.confirm(
            "Can not join the meeting now - " + msg + ". Please check and try again."
          );

          if (!confirmed || confirmed) {
            return;
          }
        }
        window.open(meetingurl.url);
//        window.location.assign(meetingurl.url);
      } catch (e) {
        alert(e);
      }
 }

 return (
    <div className="JoinMeeting">
       <p><b>Enter your name to join:</b></p>
       <form onSubmit={handleSubmit}>
          <FormGroup controlId="name">
            <FormControl
              type="input"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </FormGroup>
          <LoaderButton
            block
            type="submit"
            text="Join"
            bsSize="large"
            bsStyle="primary"
            isLoading={isLoading}
            disabled={!validateForm()}
          />
       </form>
    </div>
  );
}
