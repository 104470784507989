import React, { useState, useEffect } from "react";
import LoaderButton from "../components/LoaderButton";
import { API } from "aws-amplify";
import config from "../config";
import "./StartMeeting.css";

export default function StartMeeting(props) {
  const [meetingUrl, setMeetingUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [coldStart, setColdStart] = useState(false);

  useEffect(() => {
    function startMeeting() {
      return API.get("meetings", `/meetings/start/${props.match.params.id}`);
    }

    async function onLoad() {
      try {
        let meetingurl = await startMeeting();
        if (meetingurl.match(/ERROR:/)) {
           let msg = meetingurl.replace(/^ERROR:/, "");
           const confirmed = window.confirm(
             msg
           );
           if (!confirmed || confirmed) {
             setIsLoading(false);
             props.history.push(`/meetings/${props.match.params.id}`);
             return;
           }
        }
        
        if (meetingurl.match(/being provisioned/)) {
           setColdStart(true);
           await new Promise(resolve => setTimeout(resolve, 120000));
           meetingurl = await startMeeting();

           if (meetingurl.match(/ERROR:/)) {
             await new Promise(resolve => setTimeout(resolve, 20000));
             meetingurl = await startMeeting();
           }
        }
        setIsLoading(false);
        //const confirmed = window.confirm(
        // "Are you sure you want to goto this meeting?" + meetingurl
       // );

       //if (!confirmed) {
        // props.history.push(`/meetings/${props.match.params.id}`);
        // return;
      // }

        //window.location.assign(meetingurl);
        window.open(meetingurl);
        props.history.push(`/meetings/${props.match.params.id}`);
      } catch (e) {
        alert(e);
      }
    }
    onLoad();
  }, [props.match.params.id]);

  return (
    <div className="StartMeeting">
      <p> Starting Meeting ...</p>
      { coldStart? <p>It will take a little longer as a new cloud server is being provisioned ... You can wait or come back in 2mins</p> : null }
      <LoaderButton
          block
          type="input"
          text="Starting meeting"
          bsSize="large"
          //bsStyle="primary"
          isLoading={isLoading}
        />
    </div>
  );
}
