import React from "react";
import { Route, Switch } from "react-router-dom";

import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./containers/Home";
import About from "./containers/About";
import Records from "./containers/Records";
import Signup from "./containers/Signup";
import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPassword";
import ChangeEmail from "./containers/ChangeEmail";
import ChangePassword from "./containers/ChangePassword";
import NewMeeting from "./containers/NewMeeting";
import NewFreeMeeting from "./containers/NewFreeMeeting";
import Meetings from "./containers/Meetings";
import StartMeeting from "./containers/StartMeeting";
import JoinAMeeting from "./containers/JoinAMeeting";
import JoinMeeting from "./containers/JoinMeeting";
import JoinMeetingWithName from "./containers/JoinMeetingWithName";
import Settings from "./containers/Settings";
import Payment from "./containers/Payment";
import ReportPayments from "./containers/ReportPayments";
import NotFound from "./containers/NotFound";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      <AppliedRoute path="/about" exact component={About} appProps={appProps} />
      <AppliedRoute path="/records" exact component={Records} appProps={appProps} />
      <UnauthenticatedRoute path="/signup" exact component={Signup} appProps={appProps} />
      <AuthenticatedRoute path="/settings" exact component={Settings} appProps={appProps} />
      <AuthenticatedRoute path="/settings/email" exact component={ChangeEmail} appProps={appProps} />
      <AuthenticatedRoute path="/settings/password" exact component={ChangePassword} appProps={appProps} />
      <AuthenticatedRoute path="/settings/payment" exact component={Payment} appProps={appProps} />
      <AuthenticatedRoute path="/settings/reportpayments" exact component={ReportPayments} appProps={appProps} />
      <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
      <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} appProps={appProps} />
      <AuthenticatedRoute path="/meetings/new" exact component={NewMeeting} appProps={appProps} />
      <AuthenticatedRoute path="/meetings/newfree" exact component={NewFreeMeeting} appProps={appProps} />
      <AuthenticatedRoute path="/meetings/:id" exact component={Meetings} appProps={appProps} />
      <AuthenticatedRoute path="/meetings/start/:id" exact component={StartMeeting} appProps={appProps} />
      <AuthenticatedRoute path="/join" exact component={JoinAMeeting} appProps={appProps} />
      <AuthenticatedRoute path="/meetings/join/:id/:pw/x/:role" exact component={JoinMeeting} appProps={appProps} />
      <AuthenticatedRoute path="/meetings/join/:id/:pw/:name/:role" exact component={JoinMeetingWithName} appProps={appProps} /> 
      { /* Finally, catch all unmatched routes */ }
      <Route component={NotFound} />
    </Switch>
  );
}
