import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import config from "../config";
import "./JoinMeetingWithName.css";

export default function JoinMeetingWithName(props) {
  const [meetingUrl, setMeetingUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function loadMeeting() {
      return API.get("meetings", `/meetings/join/${props.match.params.id}/${props.match.params.pw}/${props.match.params.name}/${props.match.params.role}`);
    }

    async function onLoad() {
      try {
        const meetingurl = await loadMeeting();
        if (meetingurl.url.match(/^ERROR/)) {
          let msg = meetingurl.url.replace(/ERROR:/,"");
          const confirmed = window.confirm(
            "Can not join the meeting now - " + msg + ". Please check and try again."
          );

          if (!confirmed || confirmed) {
            return;
          }
        }

        window.open(meetingurl.url);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.match.params.id, props.match.params.role, props.match.params.pw, props.match.params.name]);

  return (
    <div className="JoinMeeting">
    </div>
  );
}
