import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup, ListGroupItem, Table } from "react-bootstrap";
import "./ReportPayments.css";
import { API } from "aws-amplify";

export default function ReportPayments(props) {
  const [payments, setPayments] = useState([]);
  const [credit, setCredit] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!props.isAuthenticated) {
        return;
      }

      try {
        const ps = await loadPayments();
        setPayments(ps);
      } catch (e) {
        alert(e);
      }

      try {
        const cs = await loadCredit();
        if (cs) {
          let bal = Math.round(cs.mvhs);
          setCredit(bal);
        }
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated]);

  function loadPayments() {
    return API.get("report", "/report/payments");
  }
  
  function loadCredit() {
    return API.get("report", "/report/credit");
  }

  function renderPaymentsList(payments) {
    return payments.map((payment, i) =>
       <tr key={i}><td>{new Date(payment.time).toLocaleString()}</td>
       <td>{payment.amount/100}</td>
       <td>{payment.mvhs}</td></tr>
    );
  }


  function renderPayments() {
    return (
      <div className="reportPayments">
        <p>My Payments:</p>
           <Table striped bordered hover size="sm">
             <thead>
              <th>Time</th>
              <th>Amount$</th>
              <th>MVHS</th>
             </thead>
             <tbody> 
               {!isLoading && renderPaymentsList(payments)}
             </tbody>
           </Table>
        <p>Balance: {credit} viewer-hours</p>
      </div>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h3>Instant Meeting Done the Righway</h3>
        <p>Please signup/login to start meetings anytime and anywhere.</p>
      </div>
    );
  }

  return (
    <div className="ReportPayments">
      {props.isAuthenticated ? renderPayments() : renderLander()}
    </div>
  );
}
