import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl, ListGroup, ListGroupItem, Button, Modal } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import config from "../config";
import "./Meetings.css";

export default function Meetings(props) {
  const [meeting, setMeeting] = useState(null);
  const [name, setName] = useState("");
  const [host, setHost] = useState("");
  const [size, setSize] = useState(0);
  const [attendeePW, setAttendeePW] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isStopping, setIsStopping] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [started, setStarted] = useState(false);
  const [show, setShow] = useState(false);
  const [emailStatus, setEmailStatus] = useState("");
  const [fields, handleFieldChange] = useFormFields({
    email: "",
  });

  useEffect(() => {
    function loadMeeting() {
      return API.get("meetings", `/meetings/${props.match.params.id}`);
    }

    async function onLoad() {
      try {
        const meeting = await loadMeeting();
        const name = meeting.meetingName;
        const host = meeting.meetingHost;
        const size = meeting.meetingSize;
        const attendeePW = meeting.meetingAttendeePW;
        const description = meeting.meetingDescription;
        setName(name);
        setHost(host);
        setSize(size);
        setAttendeePW(attendeePW);
        setDescription(description);
        setMeeting(meeting);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function validateForm() {
    return description.length > 0;
  }
 
  function validateInviteForm() {
    return fields.email.length > 0;
  }
  
  function validateEmailSent () {
    return emailStatus;
  }

  function checkCreatedMeeting() {
    return API.get("meetings", `/meetings/check/${props.match.params.id}`);
  }

  function stopMeeting() {
    return API.get("meetings", `/meetings/stop/${props.match.params.id}`);
  }

  async function handleStart(event) {

    event.preventDefault();

    setIsLoading(true);

    //setIsLoading(false);

    props.history.push(`/meetings/start/${props.match.params.id}`);
    setIsLoading(false);
    setStarted(true);
  }

  async function handleStop(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to stop this meeting?"
    );

    if (!confirmed) {
      return;
    }

    setIsStopping(true);

    try {
      const res = await stopMeeting();
     // console.log(res.message);
      props.history.push(`/meetings/${props.match.params.id}`);
    } catch (e) {
      alert(e);
    }
    setIsStopping(false);
  }

  async function handleInvite(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to invite people to this meeting?"
    );

    if (!confirmed) {
      return;
    }

    try {
      await invitePeople();
      props.history.push(`/meetings/${props.match.params.id}`);
      setEmailStatus("Invite emails have been sent. You can close this window now.");
      //handleClose();
    } catch (e) {
      alert(e);
      setIsInviting(false);
    }
  }

  function invitePeople() {
    let mname = name;
    mname = mname.replace(/Free Meeting:/, "");
    let emsg = "<br><br>It will ask you to login to join the meeting if you are not logged in.  If this is your first time, please signup quickly and then login. Please make sure your browser allow pop-up from galastage.";
    return API.post("meetings", "/meetings/email", {
      body: {
              to: fields.email,
              from: "support@galastage.com",
              subject: `Join ${host}'s ${mname} meeting at the following link`,
              content: `<a href=https://www.galastage.com/meetings/join/${props.match.params.id}/${attendeePW}/x/attendee>Click here to join</a>  ${emsg} <br><br> ${host}`,
            }
    }); 
  }

  function deleteMeeting() {
    return API.del("meetings", `/meetings/${props.match.params.id}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this meeting?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteMeeting();
      props.history.push("/");
    } catch (e) {
      alert(e);
      setIsDeleting(false);
    }
  }

  return (
    <div className="Meetings">
      {meeting && (
        <div>
          <h3>Meeting: {name}</h3>
          <p><b>Room Size:</b> {size}</p>
          <p><b>Hosted by:</b> {host}</p>
          <p><b>Attendee Password:</b> {attendeePW}</p><br/>
          <p>{description}</p>
          <br/>
          <div class="buttonbox">
            <div class="bc">
              <LoaderButton
                block
                text="Start"
                bsStyle="primary"
                onClick={handleStart}
                isLoading={isLoading}
                disabled={!validateForm() || started}
              />
            </div>
            <div className="bc">
              <LoaderButton
                block
                text="Stop"
                bsStyle="danger"
                onClick={handleStop}
                isLoading={isStopping}
              />
            </div>
            <div className="bc">
              <LoaderButton
                block
                text="Invite"
                bsStyle="primary"
                onClick={handleShow}
                isLoading={isInviting}
              />

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title><b>Invite people to this meeting:</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <FormGroup controlId="email">
                      Comma separated emails:
                      <FormControl
                        componentClass="textarea"
                        rows="6"
                        autoFocus
                        value={fields.email}
                        onChange={handleFieldChange}
                      />
                    </FormGroup>
                    <div className="EmailStatus">
                      <p> {emailStatus} </p>
                    </div>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" disabled={validateEmailSent()} onClick={handleInvite}>
                    Send invites
                  </Button>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>

            <div className="bc">
              <LoaderButton
                block
                text="Delete"
                bsStyle="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
