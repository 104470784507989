import React, { useState } from "react";
import { API } from "aws-amplify";
import { Elements, StripeProvider } from "react-stripe-elements";
import BillingForm from "../components/BillingForm";
import config from "../config";
import "./Payment.css";

export default function Payment(props) {
  const [isLoading, setIsLoading] = useState(false);

  function billUser(details) {
    return API.post("meetings", "/billing", {
      body: details
    });
  }

  async function handleFormSubmit(mvhs, { token, error }) {
    if (error) {
      alert(error);
      return;
    }

    setIsLoading(true);

    try {
      let bmsg = await billUser({
        mvhs,
        source: token.id
      });

      alert("Your card has been charged successfully! " + bmsg);
      props.history.push("/");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="Payment">
      <StripeProvider apiKey={config.STRIPE_KEY}>
        <Elements>
          <BillingForm
            isLoading={isLoading}
            onSubmit={handleFormSubmit}
          />
        </Elements>
      </StripeProvider>
    </div>
  );
}
