import React, {  useState } from "react";
import { FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import { API } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";
import "./NewMeeting.css";

export default function NewMeeting(props) {
  const [name, setName] = useState("");
  const [host, setHost] = useState("");
  const [size, setSize] = useState(0);
  const [description, setDescription] = useState("");
  const [attendeePW, setAttendeePW] = useState("password");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return description.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    const meeting = {
                      "name": name,
                      "host": host,
                      "size": size,
                      "type": "paid",
                      "attendeePW": attendeePW,
                      "description": description };
    try {
      await createMeeting(meeting);
      props.history.push("/");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function createMeeting(meeting) {
    return API.post("meetings", "/meetings", {
      body: meeting
    });
  }

  return (
    <div className="NewMeeting">
      <hr /><p><b>Input the following info to create a new meeting:</b></p><hr />
      <form onSubmit={handleSubmit}>
        <div className="fieldbox">
          <FormGroup controlId="name" className="ff">
            Meeting Name
            <FormControl
              onChange={e => setName(e.target.value)}
              value={name}
              placeholder="Give a name"
            />
          </FormGroup>
          <FormGroup controlId="host" className="ff">
            Meeting Host
            <FormControl
              value={host}
              onChange={e => setHost(e.target.value)}
              placeholder="Organizer's name"
            />
          </FormGroup>
          <FormGroup controlId="size" className="ff">
            Meeting Room Size
            <FormControl
              value={size}
              onChange={e => setSize(e.target.value)}
              placeholder="Number of attendees"
            />
          </FormGroup>
          <FormGroup controlId="password" className="ff">
            Attendee Password
            <FormControl
              value={attendeePW}
              onChange={e => setAttendeePW(e.target.value)}
            />
          </FormGroup>
        </div>
        <div className="description">
           <FormGroup controlId="description" className="cc">
             Describe your meeting
             <FormControl
               value={description}
               componentClass="textarea"
               onChange={e => setDescription(e.target.value)}
             />
           </FormGroup>
        </div>
        <LoaderButton
          block
          type="submit"
          text="Create"
          bsSize="large"
          bsStyle="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        />
      </form>
    </div>
  );
}
