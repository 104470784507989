import React from "react";
import "./Records.css";

export default function Records(props) {
    return (
      <div className="about">
        <h3>Replay previous meetings</h3>
        <p>This is under construction and will be available soon.</p>
      </div>
    );
}
